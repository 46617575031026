import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @mui
import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
import { useSettingsContext } from '../../../components/settings';
import { HEADER, NAV } from '../../../config-global';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { bgBlur } from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import Searchbar from './Searchbar';
export default function Header({ onOpenNav }) {
    const theme = useTheme();
    const { themeLayout } = useSettingsContext();
    const isNavHorizontal = themeLayout === 'horizontal';
    const isNavMini = themeLayout === 'mini';
    const isDesktop = useResponsive('up', 'lg');
    const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
    const renderContent = (_jsxs(_Fragment, { children: [isDesktop && isNavHorizontal && _jsx(Logo, { sx: { mr: 2.5 } }), !isDesktop && (_jsx(IconButton, { onClick: onOpenNav, sx: { mr: 1, color: 'text.primary' }, children: _jsx(Iconify, { icon: "eva:menu-2-fill" }) })), _jsx(Searchbar, {}), _jsxs(Stack, { flexGrow: 1, direction: "row", alignItems: "center", justifyContent: "flex-end", spacing: { xs: 0.5, sm: 1.5 }, children: [_jsx(LanguagePopover, {}), _jsx(AccountPopover, {})] })] }));
    return (_jsx(AppBar, { sx: {
            boxShadow: 'none',
            height: HEADER.H_MOBILE,
            zIndex: theme.zIndex.appBar + 1,
            backgroundColor: 'transparent !important',
            ...bgBlur({
                color: theme.palette.background.default,
            }),
            transition: theme.transitions.create(['height'], {
                duration: theme.transitions.duration.shorter,
            }),
            ...(isDesktop && {
                width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
                height: HEADER.H_DASHBOARD_DESKTOP,
                ...(isOffset && {
                    height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                }),
                ...(isNavHorizontal && {
                    width: 1,
                    bgcolor: 'transparent !important',
                    height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                    borderBottom: `dashed 1px ${theme.palette.divider}`,
                }),
                ...(isNavMini && {
                    width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
                }),
            }),
        }, children: _jsx(Toolbar, { sx: {
                height: 1,
                px: { lg: 5 },
                backgroundColor: 'transparent !important',
            }, children: renderContent }) }));
}
